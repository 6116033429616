import * as React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { CacheProvider } from '@emotion/react';

import { ThemeWrapper } from 'components/layout';
import { logException } from 'utils/analytics';
import emotionCache from 'utils/emotionCache';

import '../styles/fonts';
import 'modern-normalize';
import '@wordpress/block-library/build-style/style.css';

const progress = NProgress.configure({ showSpinner: false });

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  whitelistUrls: [/kawalcovid19\.id/i],
  release: `kawalcovid19@${process.env.NEXT_PUBLIC_COMMIT_SHA}`,
  integrations: [new Integrations.BrowserTracing()],
});

export default class MyApp extends App {
  public componentDidMount() {
    // NProgress
    Router.events.on('routeChangeStart', () => progress.start());
    Router.events.on('routeChangeComplete', () => progress.done());
    Router.events.on('routeChangeError', () => progress.done());
  }

  public componentWillUnmount() {
    // NProgress
    Router.events.off('routeChangeStart', () => progress.start());
    Router.events.off('routeChangeComplete', () => progress.done());
    Router.events.off('routeChangeError', () => progress.done());
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logException(error.message, true);
    const { componentStack } = errorInfo;

    Sentry.withScope(scope => {
      scope.setExtras({ componentStack });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });

    super.componentDidCatch(error, errorInfo);
  }

  public render() {
    const { Component, pageProps } = this.props;

    return (
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <ThemeWrapper>
          <Component {...pageProps} />
        </ThemeWrapper>
      </CacheProvider>
    );
  }
}
